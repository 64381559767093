import './CalendarViewAgenda.less'

import classNames from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { CalendarEvent } from '../../../../requests'
import { DatacBox } from '../../../common'
import { EventAgendaComponent } from '../../CalendarContent/EventComponents'
import { useCalendarStore } from '../../CalendarStore'

interface Props {
  events: CalendarEvent[]
  onClosePopup: () => void
  isSearch?: boolean
}

export const CalendarViewAgenda: React.VFC<Props> = ({ events, onClosePopup, isSearch }) => {
  const { isShowingSearchEvents } = useCalendarStore()
  const intlCalendar = useScopedIntl('calendar')
  const [days, setDays] = useState<Dayjs[]>([])

  useEffect(() => {
    if (!events?.length) {
      setDays([])
      return
    }
    setDays(
      events.reduce((acc, curr) => {
        if (acc.length && acc[acc.length - 1].format('YYYYMMDD') === curr.startFilterString) return acc

        return [...acc, curr.startDate]
      }, [] as Dayjs[])
    )

    if (isShowingSearchEvents) {
      setTimeout(() => {
        document.querySelector('.not-past-event')?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }, 1000)
    }
  }, [events])

  return (
    <div className="calendar-agenda-view">
      {days.map(day => {
        const dayFilterString = day.format('YYYYMMDD')
        const todayString = dayjs().format('YYYYMMDD')
        return (
          <DatacBox hasBorder={false} className="calendar-agenda-view__box">
            <div className="calendar-agenda-view__box__day">
              <div
                className={classNames('calendar-agenda-view__box__day__number', {
                  today: dayFilterString === todayString,
                  'not-past-event': dayFilterString >= todayString
                })}
              >
                {day.format('D')}
              </div>
              <div className="calendar-agenda-view__box__day__name">{day.format('ddd')}</div>
            </div>
            <div className="calendar-agenda-view__box__events">
              {events
                .filter(event => event.startFilterString <= dayFilterString && event.endFilterString >= dayFilterString)
                .map(event => (
                  <div className="calendar-agenda-view__box__events__event" key={event.id}>
                    <EventAgendaComponent event={event} onClosePopup={onClosePopup} />
                  </div>
                ))}
            </div>
          </DatacBox>
        )
      })}
      {!events.length && intlCalendar(isSearch ? 'no_events.search' : 'no_events.week')}
    </div>
  )
}
