import './CalendarEventEdit.less'

import { Form, Input, Popover } from 'antd'
import classNames from 'classnames'
import { Dayjs } from 'dayjs'
import React, { useContext, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import {
  CalendarEvent,
  CalendarEventColor,
  EventStudyType,
  UserConfigKey,
  createCalendarEvent,
  updateCalendarEvent
} from '../../../../requests'
import { maxTitleLengthLong, validateRequired } from '../../../../validation'
import { UserContext } from '../../../auth'
import {
  DatacFormItem,
  DatacIcon,
  DatacMessage,
  DatacModal,
  DatacOption,
  DatacSelect,
  DatacTitle
} from '../../../common'
import { useCalendarStore } from '../../CalendarStore'
import { EventEditAttendees } from './EventEditAttendees'
import { EventEditDateTime } from './EventEditDateTime'
import { EventEditStudy } from './EventEditStudy'
import { EventEditSubjects, defaultCapacity } from './EventEditSubjects'
import { parseEventPersonOption } from './EventPeopleSelect'

interface AddButtonProps {
  onClick: () => void
  children: React.ReactNode
  hasMargin?: boolean
}

const AddButton: React.FC<AddButtonProps> = ({ children, onClick, hasMargin }) => (
  <button
    className={classNames('raw', 'calendar-event-edit__add', { 'has-margin': hasMargin })}
    onClick={onClick}
    type="button"
  >
    <DatacIcon raw name="plus" />
    <span>{children}</span>
  </button>
)

type FormData = CalendarEvent & { centerId: string; date: Dayjs | Dayjs[] }

interface CalendarEventEditProps {
  onSubmitted: () => void
}

export const CalendarEventEdit: React.VFC<CalendarEventEditProps> = ({ onSubmitted }) => {
  const intlEvent = useScopedIntl('calendar.event')
  const intl = useScopedIntl('')
  const [formInstance] = Form.useForm()
  const [studyId, setStudyId] = useState<string>(null)
  const [studyType, setStudyType] = useState<EventStudyType>(null)
  const [currentSubjects, setCurrentSubjects] = useState<DatacOption[]>([])
  const [currentAttendees, setCurrentAttendees] = useState<DatacOption[]>([])
  const [currentCapacity, setCurrentCapacity] = useState(defaultCapacity)
  const [currentColor, setCurrentColor] = useState(CalendarEventColor.Blue2)
  const {
    eventToEdit,
    setEventToEdit,
    userTimezone,
    isEditEventModalVisible,
    setIsEditEventModalVisible,
    centerOptions
  } = useCalendarStore()
  const { user } = useContext(UserContext)
  const [isAddingPublicTitle, setIsAddingPublicTitle] = useState(false)
  const [isAddingStudy, setIsAddingStudy] = useState(false)
  const [isAddingParticipants, setIsAddingParticipants] = useState(false)
  const [isSavingForm, setIsSavingForm] = useState(false)
  const [isFullDay, setIsFullDay] = useState(false)

  const onSetIsFullDay = (isFullDay: boolean) => {
    setIsFullDay(isFullDay)
    const oldDate = formInstance.getFieldValue('date')
    if (isFullDay) {
      formInstance.setFieldValue('date', [oldDate, oldDate])
      return
    }
    formInstance.setFieldValue('date', oldDate?.[0])
  }

  useEffect(() => {
    setIsEditEventModalVisible(!!eventToEdit)
  }, [eventToEdit])

  useEffect(() => {
    if (!isEditEventModalVisible) return

    const savedCenterId = user.getConfigValue<string>(UserConfigKey.LastAppointmentCenter, null)
    const defaultCenterId = savedCenterId || (centerOptions.length === 1 && centerOptions[0]?.value)

    if (eventToEdit) {
      formInstance.setFieldsValue({
        organizer: eventToEdit.organizer,
        title: eventToEdit.title,
        publicTitle: eventToEdit.publicTitle,
        date: eventToEdit.isFullDay ? [eventToEdit.startDate, eventToEdit.endDate] : eventToEdit.startDate,
        startTime: eventToEdit.startTime,
        endTime: eventToEdit.endTime,
        capacity: eventToEdit.capacity || defaultCapacity,
        centerId: eventToEdit.center?.id || defaultCenterId,
        studyId: eventToEdit.study ? `${eventToEdit.study.type}.${eventToEdit.study.uuid}` : undefined,
        timezone: eventToEdit.timezone,
        attendees: eventToEdit.attendees,
        subjects: eventToEdit.subjects
      })

      setCurrentAttendees(eventToEdit.attendees?.map(parseEventPersonOption) || [])
      setCurrentSubjects(eventToEdit.subjects?.map(parseEventPersonOption) || [])
      setIsFullDay(eventToEdit.isFullDay)
      setStudyId(eventToEdit.study?.uuid)
      setStudyType(eventToEdit.study?.type)
      setCurrentColor(eventToEdit.color)
      setCurrentCapacity(eventToEdit.capacity || defaultCapacity)
      setIsAddingPublicTitle(!!eventToEdit.publicTitle)
      setIsAddingStudy(!!eventToEdit.study?.uuid)
      setIsAddingParticipants(!!eventToEdit.capacity)
      setTimeout(() => formInstance.validateFields(), 10) // needed to show labels on input border if there is a value
      return
    }

    formInstance.resetFields()
    formInstance.setFieldsValue({ capacity: defaultCapacity, centerId: defaultCenterId })
    setIsFullDay(false)
    setCurrentColor(CalendarEventColor.Blue2)
    setCurrentSubjects([])
    setCurrentAttendees([])
    setCurrentCapacity(defaultCapacity)
    setStudyId(null)
    setStudyType(null)
    setIsAddingPublicTitle(false)
    setIsAddingStudy(false)
    setIsAddingParticipants(false)
  }, [isEditEventModalVisible])

  const onSelectCenter = (centerId: string) => {
    user.setConfigValue(UserConfigKey.LastAppointmentCenter, centerId)
  }

  const onSubmit = (data: FormData) => {
    if (currentSubjects.length > data.capacity) return
    setIsSavingForm(true)
    ;(eventToEdit?.id ? updateCalendarEvent : createCalendarEvent)(
      {
        ...data,
        startDate: isFullDay ? (data.date as Dayjs[])[0] : (data.date as Dayjs),
        endDate: isFullDay ? (data.date as Dayjs[])[1] : (data.date as Dayjs),
        isFullDay,
        subjects: currentSubjects.map(s => s.value),
        attendees: currentAttendees.map(a => a.value),
        study: { uuid: studyId, type: studyType },
        center: { id: data.centerId },
        color: currentColor,
        id: eventToEdit?.id || undefined,
        organizer: eventToEdit?.organizer || user.id,
        timezone: data.timezone || eventToEdit?.timezone || userTimezone
      },
      {
        onSuccess: () => {
          onModalClose()
          onSubmitted()
          setIsSavingForm(false)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsSavingForm(false)
        }
      }
    )
  }

  const colorPopoverContent = (
    <div className="calendar-event-edit__modal__color-picker__content">
      {Object.values(CalendarEventColor).map(color => (
        <button
          type="button"
          key={color}
          className={classNames(
            'calendar-event-edit__modal__color-picker__color',
            `calendar-content__background--${color}`
          )}
          onClick={() => setCurrentColor(color)}
        />
      ))}
    </div>
  )

  const onModalClose = () => {
    setIsEditEventModalVisible(false)
    setEventToEdit(null)
  }

  return (
    <DatacModal
      className="calendar-event-edit__modal"
      isOpened={isEditEventModalVisible}
      onClose={onModalClose}
      onSubmit={() => formInstance.submit()}
      title={intlEvent(eventToEdit?.id ? 'update' : 'add')}
      submitLabel={intl(eventToEdit?.id ? 'common.update' : 'common.add')}
      loading={isSavingForm}
      destroyOnClose
      footerOption={
        <Popover trigger="click" placement="top" content={colorPopoverContent}>
          <div className="calendar-event-edit__modal__color-picker">
            <div
              className={classNames(
                'calendar-event-edit__modal__color-picker__color',
                `calendar-content__background--${currentColor}`
              )}
            />
            <DatacIcon name="chevronDown" raw />
          </div>
        </Popover>
      }
    >
      <Form form={formInstance} name="NewEventForm" onFinish={onSubmit}>
        <div className="calendar-event-edit__box">
          <DatacFormItem
            name="title"
            validate={validateRequired(intl('common.required'))}
            icon="clipboard"
            isCalendarStyle
            label={intlEvent('title.placeholder')}
          >
            <Input size="large" placeholder={intlEvent('title.placeholder')} maxLength={maxTitleLengthLong} />
          </DatacFormItem>

          {isAddingPublicTitle ? (
            <DatacFormItem
              name="publicTitle"
              icon="alertCircle"
              isCalendarStyle
              label={intlEvent('public.title')}
              validate={[]}
            >
              <Input size="large" placeholder={intlEvent('public.title')} maxLength={maxTitleLengthLong} />
            </DatacFormItem>
          ) : (
            <AddButton onClick={() => setIsAddingPublicTitle(true)} hasMargin>
              {intlEvent('public.title')}
            </AddButton>
          )}

          <EventEditDateTime
            isVisible={isEditEventModalVisible}
            formInstance={formInstance}
            isFullDay={isFullDay}
            setIsFullDay={onSetIsFullDay}
          />

          <EventEditAttendees
            isVisible={isEditEventModalVisible}
            currentAttendees={currentAttendees}
            setCurrentAttendees={setCurrentAttendees}
          />

          <DatacFormItem
            name="centerId"
            icon="mapPin"
            showAsterisk
            validate={validateRequired(intl('common.required'))}
            isCalendarStyle
            label={intlEvent('location.placeholder')}
          >
            <DatacSelect
              showSearch
              options={centerOptions}
              placeholder={intlEvent('location.placeholder')}
              onChange={onSelectCenter}
            />
          </DatacFormItem>
        </div>

        <div className="calendar-event-edit__box">
          <DatacTitle size="small">{intlEvent('advanced')}</DatacTitle>
          <div className="calendar-event-edit__advanced-buttons">
            {!isAddingStudy && <AddButton onClick={() => setIsAddingStudy(true)}>{intlEvent('add_study')}</AddButton>}
            {!isAddingParticipants && (
              <AddButton onClick={() => setIsAddingParticipants(true)}>{intlEvent('add_participants')}</AddButton>
            )}
          </div>
          {isAddingStudy && (
            <EventEditStudy
              studyId={studyId}
              setStudyId={setStudyId}
              studyType={studyType}
              setStudyType={setStudyType}
            />
          )}
          {isAddingParticipants && (
            <EventEditSubjects
              isVisible={isEditEventModalVisible}
              formInstance={formInstance}
              currentSubjects={currentSubjects}
              setCurrentSubjects={setCurrentSubjects}
              currentCapacity={currentCapacity}
              setCurrentCapacity={setCurrentCapacity}
            />
          )}
        </div>
      </Form>
    </DatacModal>
  )
}
