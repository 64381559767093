import './CalendarViewWeek.less'

import classNames from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import React, { ReactNode, useEffect, useRef, useState } from 'react'

import { CalendarEvent } from '../../../../requests'
import { localeFromPath } from '../../../../utils'
import { DatacIcon } from '../../../common'
import { useCalendarStore } from '../../CalendarStore'
import { CalendarHourOnGrid, CalendarViewDay, CalendarViewDayCurrentTime } from '../CalendarViewDay'
import { createEventSlotsInWeek } from '../CalendarViewMonth'

interface Props {
  events: CalendarEvent[]
  onClosePopup: () => void
}

export const CalendarViewWeek: React.VFC<Props> = ({ events, onClosePopup }) => {
  const { currentDate, setCurrentDate, setCurrentView, startsOnSunday, showWeekends } = useCalendarStore()
  const [week, setWeek] = useState<Dayjs[]>([])
  const [fullDayEvents, setFullDayEvents] = useState<CalendarEvent[]>([])
  const [oneDayWidth, setOneDayWidth] = useState(0)
  const [fullDaySlots, setFullDaySlots] = useState<ReactNode[][]>([])
  const weekBodyRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setFullDayEvents(events.filter(e => e.isFullDay))
  }, [events])

  const getOneDayWidth = () => {
    const dayCellDiv = document.querySelector('.calendar-view-week__body__day')
    setOneDayWidth(dayCellDiv?.clientWidth || 0)
  }

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => getOneDayWidth())

    if (events?.length && weekBodyRef.current) {
      resizeObserver.observe(weekBodyRef.current)
      setTimeout(() => getOneDayWidth(), 10)
    }

    return () => resizeObserver.disconnect()
  }, [events, weekBodyRef.current, showWeekends])

  useEffect(() => {
    dayjs.updateLocale(localeFromPath(), { weekStart: startsOnSunday ? 0 : 1 })

    setWeek(
      [0, 1, 2, 3, 4, 5, 6]
        .slice(0, showWeekends ? 7 : 5)
        .map(day => currentDate.clone().startOf('week').add(day, 'day'))
    )
  }, [currentDate, startsOnSunday, showWeekends])

  useEffect(() => {
    if (!fullDayEvents?.length || !week?.length || !oneDayWidth) return
    setFullDaySlots(createEventSlotsInWeek(week[0], fullDayEvents, showWeekends, oneDayWidth, onClosePopup))
  }, [fullDayEvents, startsOnSunday, showWeekends, oneDayWidth])

  const goToDay = (day: Dayjs) => {
    setCurrentDate(day)
    setCurrentView('day')
  }

  const timezoneOffset = parseInt(currentDate.format('Z'), 10)
  return (
    <div className="calendar-view-week">
      <div className="calendar-view-week__header">
        <div className="calendar-view-week__header__corner">
          <DatacIcon raw name="clock" />
          <span>
            GMT{timezoneOffset >= 0 && '+'}
            {timezoneOffset}
          </span>
        </div>
        {week.map((day, index) => (
          <button
            type="button"
            key={index}
            className="raw calendar-view-week__header__day"
            onClick={() => goToDay(day)}
          >
            <div>{day.format('dddd')}</div>
            <div
              className={classNames('calendar-view-week__header__day__number', { current: dayjs().isSame(day, 'day') })}
            >
              {day.format('DD')}
            </div>
          </button>
        ))}
      </div>

      <div className="calendar-view-week__body" ref={weekBodyRef}>
        {!!fullDayEvents.length && (
          <div className="calendar-view-week__body__full-day">
            <div className="calendar-view-week__body__full-day__corner" />
            {fullDaySlots.map((day, index) => (
              <div key={index} className="calendar-view-week__body__full-day__day">
                {day}
              </div>
            ))}
          </div>
        )}
        <div className="calendar-view-week__body__time">
          <div className="calendar-view-week__body__hours">
            <CalendarViewDayCurrentTime showTime />
            {Array.from({ length: 24 }).map((_, i) => (
              <div key={i} className="calendar-view-day__row">
                <div className="calendar-view-day__row__label">
                  <CalendarHourOnGrid hour={i} isToday={dayjs().isSame(currentDate, 'week')} />
                </div>
              </div>
            ))}
          </div>
          {week.map((day, index) => (
            <div key={index} className="calendar-view-week__body__day">
              <CalendarViewDay dayToShow={day} events={events} onClosePopup={onClosePopup} shouldShowFullDay={false} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
