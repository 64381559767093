import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect, useState } from 'react'

import { useCalendarStore } from '../../CalendarStore'

export const getDayColumnWidth = () => {
  const dayCellDiv =
    document.querySelector('.calendar-view-day__row__cell') || document.querySelector('.calendar-view-day__row__label')
  return dayCellDiv.clientWidth || 0
}

export const getDayColumnOffset = () => {
  const dayLabelDiv = document.querySelector('.calendar-view-day__row__label')
  return dayLabelDiv.clientWidth || 0
}

export const getTimeTopPosition = (date: Dayjs) => {
  const currentHour = date.hour()
  const currentMinute = date.minute()

  const dayDiv =
    document.querySelector('.calendar-view-day__hours') || document.querySelector('.calendar-view-week__body__time')

  const divHeight = dayDiv?.clientHeight || 0

  const totalMinutesInDay = 24 * 60
  const currentMinutesInDay = currentHour * 60 + currentMinute

  const timeRatio = currentMinutesInDay / totalMinutesInDay

  return timeRatio * divHeight
}

export const getPositionToTime = (y: number, day: Dayjs, roundedToQuarter?: boolean): Dayjs => {
  const dayDiv = document.querySelector('.calendar-view-day__hours')
  const divHeight = dayDiv.clientHeight || 0

  const totalMinutesInDay = 24 * 60

  const exactTime = day.startOf('day').add((y * totalMinutesInDay) / divHeight, 'minutes')
  if (!roundedToQuarter) return exactTime

  const minutes = exactTime.minute()
  const roundedMinutes = Math.round(minutes / 15) * 15
  return exactTime.minute(roundedMinutes).second(0)
}

export const CalendarViewDayCurrentTime: React.VFC<{ showTime?: boolean }> = ({ showTime }) => {
  const [currentTimeTop, setCurrentTimeTop] = useState(0)
  const { currentDate, currentView } = useCalendarStore()

  useEffect(() => {
    setTimeout(() => setCurrentTimeTop(getTimeTopPosition(dayjs())), 100)

    const myInterval = setInterval(() => setCurrentTimeTop(getTimeTopPosition(dayjs())), 10000)
    return () => clearInterval(myInterval)
  }, [])

  if (!dayjs().isSame(currentDate, currentView === 'week' ? 'week' : 'day')) return null

  return (
    <div className="calendar-view-day__current-time" style={{ top: `${currentTimeTop}px` }}>
      {showTime && (
        <div className="calendar-view-day__current-time__time">
          <div className="calendar-view-day__current-time__time__label">{dayjs().format('HH:mm')}</div>
          <div className="calendar-view-day__current-time__time__dot">&bull;</div>
        </div>
      )}
    </div>
  )
}

export const CalendarHourOnGrid: React.VFC<{ isToday: boolean; hour: number }> = ({ isToday, hour }) => {
  const [isHourHidden, setIsHourHidden] = useState(false)

  const shouldHideTime = () => {
    return !hour || (isToday && Math.abs(dayjs().hour(hour).minute(0).second(0).diff(dayjs(), 'minute')) < 5)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setIsHourHidden(shouldHideTime())
    }, 30000)

    setIsHourHidden(shouldHideTime())
    return () => clearInterval(interval)
  }, [])

  return !isHourHidden && <span>{hour}:00</span>
}
