import dayjs, { Dayjs } from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import React, { ReactNode } from 'react'

import { CalendarEvent } from '../../../../requests'
import { EventEmptySpot, EventFullDayComponent, EventMonthComponent } from '../../CalendarContent/EventComponents'

dayjs.extend(isoWeek)

interface ReservedSpot {
  day: Dayjs
  row: number
}

export const createEventSlotsInWeek = (
  weekStart: Dayjs,
  events: CalendarEvent[],
  showWeekends: boolean,
  oneDayWidth: number,
  onClosePopup: () => void
) => {
  const reservedSpots: ReservedSpot[] = []
  const days: ReactNode[][] = []
  const weekEnd = weekStart.clone().add(showWeekends ? 6 : 4, 'days')
  const daysArray = [1, 2, 3, 4, 5, 6, 7]
  const week = (showWeekends ? daysArray : daysArray.slice(0, 5)).map(day => {
    return dayjs().day(day)
  })

  week.forEach((_, dayIndex) => {
    const eventComponents: React.ReactNode[] = []
    const day = weekStart.clone().add(dayIndex, 'day')
    const dayFilterString = day.format('YYYYMMDD')
    const dayReservedSpots = reservedSpots.filter(s => s.day.isSame(day, 'day')).map(s => s.row)
    let row = 0

    events
      .filter(
        e =>
          e.startFilterString === dayFilterString ||
          (!dayIndex && e.startFilterString < dayFilterString && e.endFilterString >= dayFilterString)
      )
      .forEach(event => {
        while (dayReservedSpots.includes(row)) {
          eventComponents.push(<EventEmptySpot key={row} />)
          row += 1
        }
        const start = event.startDate.isBefore(weekStart, 'day') ? weekStart : event.startDate
        const end = event.endDate.isAfter(weekEnd, 'day') ? weekEnd : event.endDate
        const daysNr = end.diff(start, 'day') + 1
        const width = `${oneDayWidth * daysNr}px`
        eventComponents.push(
          event.isFullDay ? (
            <EventFullDayComponent key={row} event={event} onClosePopup={onClosePopup} width={width} />
          ) : (
            <EventMonthComponent key={row} event={event} onClosePopup={onClosePopup} />
          )
        )
        for (let i = 0; i < daysNr; i += 1) {
          reservedSpots.push({ day: day.add(i, 'day'), row })
        }
        row += 1
      })

    days.push(eventComponents)
  })

  week.forEach((_, dayIndex) => {
    const day = weekStart.clone().add(dayIndex, 'day')
    const spotsCount = reservedSpots
      .filter(s => s.day.isSame(day, 'day'))
      .reduce((acc, curr) => (curr.row > acc ? curr.row : acc), 0)

    const addedSpotsCount = days[dayIndex]?.length || 0
    for (let i = addedSpotsCount; i <= spotsCount; i += 1) {
      days[dayIndex].push(<EventEmptySpot key={i} />)
    }
  })

  return days
}
