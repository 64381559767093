import './EventEditAttendees.less'

import React from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { DatacFormItem, DatacInformationMessage, DatacOption } from '../../../../common'
import { EventPeopleSelect, EventPeopleSelectList, PeopleSelectType } from '../EventPeopleSelect'

interface EventEditAttendeesProps {
  isVisible: boolean
  currentAttendees: DatacOption[]
  setCurrentAttendees: (attendees: DatacOption[]) => void
}

export const EventEditAttendees: React.VFC<EventEditAttendeesProps> = ({
  isVisible,
  currentAttendees,
  setCurrentAttendees
}) => {
  const intlEvent = useScopedIntl('calendar.event')

  return (
    <>
      {currentAttendees.some(a => !a.sublabel) && (
        <DatacInformationMessage type="warning" message={intlEvent('no_email.warning')} />
      )}
      <DatacFormItem name="attendees" icon="user" isCalendarStyle>
        <EventPeopleSelect
          currentSelection={currentAttendees}
          setCurrentSelection={setCurrentAttendees}
          isVisible={isVisible}
          type={PeopleSelectType.Attendees}
        />
      </DatacFormItem>
      <EventPeopleSelectList currentSelection={currentAttendees} setCurrentSelection={setCurrentAttendees} />
    </>
  )
}
