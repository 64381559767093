import './CalendarSettingsPopup.less'

import { Switch } from 'antd'
import classNames from 'classnames'
import React, { useContext } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { UserConfigKey } from '../../../../requests'
import { UserContext } from '../../../auth'
import { useCalendarStore } from '../../CalendarStore'

export const CalendarSettingsPopup: React.VFC<{ onClose: () => void }> = ({ onClose }) => {
  const {
    showWeekends,
    setShowWeekends,
    startsOnSunday,
    setStartsOnSunday,
    showSchedules,
    setShowSchedules,
    isToolbarMinimized,
    setIsToolbarMinimized
  } = useCalendarStore()
  const intlSettings = useScopedIntl('calendar.settings')
  const { user } = useContext(UserContext)
  const onShowWeekendsChange = (checked: boolean) => {
    setShowWeekends(checked)
    user.setConfigValue(UserConfigKey.CalendarShowWeekends, checked)

    if (!checked) {
      setStartsOnSunday(false)
      user.setConfigValue(UserConfigKey.CalendarStartsOnSunday, false)
    }
  }

  const onStartsOnSundayChange = (checked: boolean) => {
    setStartsOnSunday(checked)
    user.setConfigValue(UserConfigKey.CalendarStartsOnSunday, checked)
  }

  const onShowSchedulesChange = (checked: boolean) => {
    setShowSchedules(checked)
    user.setConfigValue(UserConfigKey.CalendarShowSchedules, checked)
  }

  const onMinimizeToolbarChange = (checked: boolean) => {
    onClose()
    setIsToolbarMinimized(checked)
    user.setConfigValue(UserConfigKey.CalendarMinimizeToolbar, checked)
  }

  return (
    <div className={classNames('calendar-settings-popup', { minimized: isToolbarMinimized })}>
      <div className="calendar-settings-popup__row">
        <Switch checked={showWeekends} onChange={onShowWeekendsChange} />
        <span>{intlSettings('show_weekends')}</span>
      </div>
      <div className="calendar-settings-popup__row">
        <Switch disabled={!showWeekends} checked={startsOnSunday} onChange={onStartsOnSundayChange} />
        <span>{intlSettings('start_week_on_sunday')}</span>
      </div>
      <div className="calendar-settings-popup__row">
        <Switch checked={showSchedules} onChange={onShowSchedulesChange} />
        <span>{intlSettings('show_schedules')}</span>
      </div>
      <div className="calendar-settings-popup__row">
        <Switch checked={isToolbarMinimized} onChange={onMinimizeToolbarChange} />
        <span>{intlSettings('minimize_toolbar')}</span>
      </div>
    </div>
  )
}
