import './EventComponents.less'

import classNames from 'classnames'
import dayjs from 'dayjs'
import React from 'react'

import { useScopedIntl } from '../../../../hooks'
import { CalendarEvent, CalendarEventColor } from '../../../../requests'
import { DatacIcon } from '../../../common'
import { useCalendarStore } from '../../CalendarStore'
import { CalendarEventPopup } from '../CalendarEventPopup'

interface EventComponent {
  event: CalendarEvent
  onClosePopup: (reload: boolean) => void
  showDate?: boolean
  isInSearch?: boolean
}

const getTimeInMinutes = (time: string) => {
  const timeArray = time.split(':').map(Number)
  return timeArray[0] * 60 + timeArray[1]
}

interface CapacityProps {
  subjectCount: number
  capacity: number
  agenda?: boolean
}

const Capacity: React.VFC<CapacityProps> = ({ subjectCount, capacity, agenda }) => (
  <div
    className={classNames(
      'calendar-components__capacity',
      capacity === subjectCount && 'calendar-components__capacity--full',
      agenda && 'calendar-components__capacity--agenda',
      !capacity && 'calendar-components__capacity--empty'
    )}
  >
    {subjectCount || 0} / {capacity || 0}
  </div>
)

const Dot: React.VFC<{ color: CalendarEventColor; detached?: boolean; isFullDay?: boolean }> = ({
  color,
  detached,
  isFullDay
}) => (
  <div className={classNames('calendar-components__dot', `calendar-content__background--${color}`, { detached })}>
    {isFullDay && <DatacIcon name="calendar" raw />}
  </div>
)

export const EventWeekComponent: React.VFC<EventComponent> = ({ event, onClosePopup }) => {
  const { startDate, endDate, startTime, endTime, subjects, study, capacity, title, color } = event
  const { userTimezone } = useCalendarStore()
  const duration = getTimeInMinutes(endTime) - getTimeInMinutes(startTime)
  const start = dayjs.tz(startDate, userTimezone).format('HH:mm')
  const end = dayjs.tz(endDate, userTimezone).format('HH:mm')
  const isPast = endDate.isBefore(dayjs(), 'minute')

  return (
    <CalendarEventPopup event={event} onClose={onClosePopup}>
      <div className="calendar-components__wrapper">
        <div
          className={classNames(
            'calendar-components__event',
            `calendar-content__background__light--${color}`,
            isPast && 'calendar-components--past'
          )}
        >
          <Dot color={color} />
          <div className="calendar-components__event__content">
            <div className="calendar-components__event__content__header">
              <span>
                {start} - {end}
              </span>
              <Capacity subjectCount={subjects?.length} capacity={capacity} />
            </div>
            {duration > 15 && <div>{title}</div>}
            {duration > 30 && study?.reference && <span>{study.reference}</span>}
          </div>
        </div>
      </div>
    </CalendarEventPopup>
  )
}

interface FullDayEventComponent {
  event: CalendarEvent
  onClosePopup: (reload: boolean) => void
  width?: string
}

export const EventFullDayComponent: React.VFC<FullDayEventComponent> = ({ event, onClosePopup, width }) => {
  const { title, color, endDate, capacity, subjects } = event
  const isPast = endDate.isBefore(dayjs(), 'minute')

  return (
    <CalendarEventPopup event={event} onClose={onClosePopup} placement="bottom">
      <div className="calendar-components__full-day" style={width && { width }}>
        <div
          className={classNames(
            'calendar-components__full-day__inner',
            `calendar-content__background__light--${color}`,
            isPast && 'calendar-components--past'
          )}
        >
          <Dot color={color} isFullDay />
          <div className="calendar-components__full-day__title">{title}</div>
          <Capacity subjectCount={subjects?.length} capacity={capacity} />
        </div>
      </div>
    </CalendarEventPopup>
  )
}

export const EventEmptySpot: React.VFC = () => <div className="calendar-components__empty" />

export const EventAgendaComponent: React.VFC<EventComponent> = ({ event, onClosePopup, isInSearch }) => {
  const intlEvent = useScopedIntl('calendar.event')
  const { startDate, endDate, title, color, subjects, capacity, study, isFullDay } = event
  const { userTimezone } = useCalendarStore()
  const isPast = endDate.isBefore(dayjs(), 'minute')
  const start = dayjs.tz(startDate, userTimezone)
  const end = dayjs.tz(endDate, userTimezone)
  const componentInner = (
    <div
      className={classNames(
        'calendar-components__agenda',
        isPast && 'calendar-components--past',
        isInSearch && 'calendar-components__agenda--in-search'
      )}
    >
      {isInSearch && <div className="calendar-components__agenda__date">{start.format('D MMM YYYY')}</div>}

      <div className="calendar-components__agenda__time">
        {isFullDay ? intlEvent('all_day') : `${start.format('HH:mm')} - ${end.format('HH:mm')}`}
      </div>
      {!isInSearch && <Capacity agenda subjectCount={subjects?.length} capacity={capacity} />}
      <Dot color={color} detached />
      <div className="calendar-components__agenda__title">{title}</div>
      <div className="calendar-components__agenda__reference">{study?.reference}</div>
    </div>
  )
  return isInSearch ? (
    componentInner
  ) : (
    <CalendarEventPopup event={event} onClose={onClosePopup}>
      {componentInner}
    </CalendarEventPopup>
  )
}

export const EventMonthComponent: React.VFC<EventComponent> = ({ event, onClosePopup }) => {
  const { startDate, endDate, title, color, subjects, capacity } = event
  const { userTimezone } = useCalendarStore()
  const isPast = endDate.isBefore(dayjs(), 'minute')
  const start = dayjs.tz(startDate, userTimezone).format('HH:mm')
  return (
    <CalendarEventPopup event={event} onClose={onClosePopup}>
      <div
        className={classNames(
          'calendar-components__month',
          `calendar-content__background__light--${color}`,
          isPast && 'calendar-components--past'
        )}
      >
        <Dot color={color} />
        <div className="calendar-components__month__start">{start}</div>
        <div className="calendar-components__month__title">{title}</div>
        <Capacity subjectCount={subjects?.length} capacity={capacity} />
      </div>
    </CalendarEventPopup>
  )
}
