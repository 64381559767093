import './EventEditDateTime.less'

import { DatePicker, RefSelectProps, Switch } from 'antd'
import { FormInstance } from 'antd/lib/form'
import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect, useRef, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { validateRequired } from '../../../../../validation'
import { DatacFormItem, DatacIcon, DatacOption, DatacSelect } from '../../../../common'
import { useCalendarStore } from '../../../CalendarStore'

const { RangePicker } = DatePicker

interface EventEditDateTimeProps {
  isVisible: boolean
  formInstance: FormInstance
  isFullDay: boolean
  setIsFullDay: (isFullDay: boolean) => void
}

export const EventEditDateTime: React.VFC<EventEditDateTimeProps> = ({
  isVisible,
  formInstance,
  isFullDay,
  setIsFullDay
}) => {
  const intlEvent = useScopedIntl('calendar.event')
  const intl = useScopedIntl('')
  const { eventToEdit } = useCalendarStore()

  const [startTime, setStartTime] = useState(dayjs().format('HH:mm'))
  const startTimeRef = useRef<RefSelectProps>()
  const endTimeRef = useRef<RefSelectProps>()
  const [currentStartTime, setCurrentStartTime] = useState('')
  const [currentEndTime, setCurrentEndTime] = useState('')
  const [startTimeOptions, setStartTimeOptions] = useState<DatacOption[]>([])
  const [endTimeOptions, setEndTimeOptions] = useState<DatacOption[]>([])

  useEffect(() => {
    if (!isVisible) return
    setStartTime(eventToEdit?.startTime)
    setCurrentStartTime(eventToEdit?.startTime)
    setCurrentEndTime(eventToEdit?.endTime)
    setStartTimeOptions(getTimeOptions())
    setEndTimeOptions(getTimeOptions(true))
  }, [isVisible])

  useEffect(() => {
    setEndTimeOptions(getTimeOptions(true))
  }, [startTime])

  useEffect(() => {
    const startTime = formInstance.getFieldValue('startTime')
    const startDate = formInstance.getFieldValue('startDate')
    if (!startTime) onPickerChange(startDate)
  }, [startTimeOptions])

  useEffect(() => {
    const endTime = formInstance.getFieldValue('endTime')
    const startTime = formInstance.getFieldValue('startTime')

    if (startTime && (startTime >= endTime || !endTime)) formInstance.resetFields(['endTime'])
  }, [endTimeOptions])

  const getLowestPossibleStartTime = (date: Dayjs) => {
    const now = dayjs()
    const roundedMinutes = Math.ceil(now.minute() / 15) * 15
    return date?.isSame(dayjs(), 'day') ? now.minute(roundedMinutes).format('HH:mm') : '00:00'
  }

  const getTimeOptions = (isEnd = false) => {
    const starting = startTime?.split(':').map(Number) || [0, 0]
    const times = Array.from({ length: 24 }, (_, i) => i).reduce((acc, hour) => {
      if (isEnd && hour < starting[0]) return acc
      ;[0, 15, 30, 45].forEach(minute => {
        if (!isEnd || hour !== starting[0] || minute >= starting[1])
          acc.push(`${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`)
      })
      return acc
    }, [])

    if (isEnd) {
      times.shift()
      times.push('23:59')
    }

    const getDurationString = (endTime: string) => {
      if (!isEnd || !startTime) return ''
      const duration = dayjs.duration(dayjs(endTime, 'HH:mm').diff(dayjs(startTime, 'HH:mm')))
      return `(${intlEvent('duration', { value: dayjs.utc(duration.as('milliseconds')).format('HH:mm') })})`
    }

    return times.map(time => ({
      value: time,
      label: `${time}`,
      sublabel: getDurationString(time)
    }))
  }

  const onPickerChange = (value: Dayjs) => {
    setStartTimeOptions(getTimeOptions())
    setEndTimeOptions(getTimeOptions(true))
    const possibleStartTime = getLowestPossibleStartTime(value)
    if (startTime <= possibleStartTime) {
      setStartTime(possibleStartTime)
      formInstance.setFieldsValue({ startTime: possibleStartTime })
    }
  }

  const shouldChangeTime = (key: string, time: string, notLessThan: string) => {
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/
    return key === 'Enter' && timeRegex.test(time) && time >= notLessThan
  }

  const onStartTimeKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!shouldChangeTime(e.key, currentStartTime, startTimeOptions[0]?.value)) return
    formInstance.setFieldsValue({ startTime: currentStartTime })
    setCurrentStartTime('')
    setStartTime(currentStartTime)
    startTimeRef.current.blur()
  }

  const onEndTimeKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!shouldChangeTime(e.key, currentEndTime, endTimeOptions[0]?.value)) return
    formInstance.setFieldsValue({ endTime: currentEndTime })
    setCurrentEndTime('')
    endTimeRef.current.blur()
  }

  return (
    <div className="calendar-event-edit-date-time">
      <div className="calendar-event-edit-date-time__date">
        <DatacFormItem
          showAsterisk
          name="date"
          validate={validateRequired(intl('common.required'))}
          icon="calendar"
          initialValue={dayjs()}
          isCalendarStyle
          label={intlEvent('date.placeholder')}
        >
          {isFullDay ? (
            <RangePicker
              size="large"
              format="dddd, D MMM YYYY"
              onChange={values => onPickerChange(values?.[0])}
              placeholder={[intlEvent('start_date.placeholder'), intlEvent('end_date.placeholder')]}
            />
          ) : (
            <DatePicker
              size="large"
              format="dddd, D MMM YYYY"
              onChange={onPickerChange}
              placeholder={intlEvent('date.placeholder')}
            />
          )}
        </DatacFormItem>
        <Switch checked={isFullDay} onChange={setIsFullDay} />
        <div className="calendar-event-edit-date-time__date__label">{intlEvent('all_day')}</div>
      </div>

      {!isFullDay && (
        <div className="calendar-event-edit-date-time__time">
          <DatacFormItem
            name="startTime"
            validate={validateRequired(intl('common.required'))}
            initialValue={startTimeOptions[0]?.value}
            label={intlEvent('start_time.placeholder')}
            isCalendarStyle
          >
            <DatacSelect
              showSearch
              size="large"
              options={startTimeOptions}
              onChange={setStartTime}
              onInputKeyDown={onStartTimeKeyPressed}
              selectRef={startTimeRef}
              onSearch={setCurrentStartTime}
              placeholder={intlEvent('start_time.placeholder')}
            />
          </DatacFormItem>
          <div className="calendar-event-edit-date-time__separator">
            <DatacIcon raw name="arrowRight" />
          </div>
          <DatacFormItem
            name="endTime"
            validate={validateRequired(intl('common.required'))}
            initialValue={endTimeOptions[0]?.value}
            label={intlEvent('end_time.placeholder')}
            isCalendarStyle
          >
            <DatacSelect
              showSearch
              size="large"
              options={endTimeOptions}
              onInputKeyDown={onEndTimeKeyPressed}
              selectRef={endTimeRef}
              onSearch={setCurrentEndTime}
              dropDownClassName="calendar-event-edit-date-time__end-dropdown"
              placeholder={intlEvent('end_time.placeholder')}
            />
          </DatacFormItem>
        </div>
      )}
    </div>
  )
}
