import './CalendarViewMonth.less'

import classNames from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import React, { ReactNode, useEffect, useState } from 'react'

import { CalendarEvent } from '../../../../requests'
import { localeFromPath } from '../../../../utils'
import { CalendarDayMore } from '../../CalendarContent/CalendarDayMore'
import { useCalendarStore } from '../../CalendarStore'
import { createEventSlotsInWeek } from './CalendarViewMonthCreateSlots'

dayjs.extend(isoWeek)

interface DayCellProps {
  day: Dayjs
  allEvents: CalendarEvent[]
  dayEvents: ReactNode[]
  maxEventsInDay: number
  onClosePopup: () => void
  width: string
  moreCount: number
}

const DayCell: React.VFC<DayCellProps> = ({
  day,
  allEvents,
  dayEvents,
  maxEventsInDay,
  onClosePopup,
  width,
  moreCount
}) => {
  const { currentDate, showWeekends, setCurrentDate, setCurrentView } = useCalendarStore()

  if (!showWeekends && (day.isoWeekday() === 6 || day.isoWeekday() === 7)) return null

  const goToDay = (day: Dayjs) => {
    setCurrentDate(day)
    setCurrentView('day')
  }

  return (
    <div
      className={classNames('calendar-view-month__body__week__day', {
        grey: !day.isSame(currentDate, 'month')
      })}
      id={day.format('MM-DD')}
      style={{ width }}
    >
      <div
        className={classNames('calendar-view-month__body__week__day__label', {
          today: dayjs().isSame(day, 'day')
        })}
        onClick={() => goToDay(day)}
      >
        {day.format('D')}
      </div>
      {dayEvents
        .slice(0, maxEventsInDay)
        .map((event, i) =>
          i === maxEventsInDay - 1 ? (
            <CalendarDayMore
              key={i}
              day={day.toDate()}
              events={allEvents}
              onClosePopup={onClosePopup}
              moreCount={moreCount}
            />
          ) : (
            event
          )
        )}
    </div>
  )
}

interface CalendarViewMonthProps {
  events: CalendarEvent[]
  onClosePopup: () => void
}

export const CalendarViewMonth: React.VFC<CalendarViewMonthProps> = ({ events, onClosePopup }) => {
  const { currentDate, startsOnSunday, showWeekends, isSidebarVisible } = useCalendarStore()
  const [week, setWeek] = useState<Dayjs[]>([])
  const [startDay, setStartDay] = useState<Dayjs>()
  const [endDay, setEndDay] = useState<Dayjs>()
  const [numberOfWeeks, setNumberOfWeeks] = useState(0)
  const [maxEventsInDay, setMaxEventsInDay] = useState(5)
  const [oneDayWidth, setOneDayWidth] = useState(0)
  const [currentEventsInDays, setCurrentEventsInDays] = useState<ReactNode[][][]>([])

  useEffect(() => {
    dayjs.updateLocale(localeFromPath(), { weekStart: startsOnSunday ? 0 : 1 })

    const daysArray = startsOnSunday ? [0, 1, 2, 3, 4, 5, 6] : [1, 2, 3, 4, 5, 6, 7]
    setWeek(
      (showWeekends ? daysArray : daysArray.slice(0, 5)).map(day => {
        return dayjs().day(day)
      })
    )

    setStartDay(currentDate.startOf('month').startOf('week'))
    setEndDay(currentDate.endOf('month').endOf('week'))
  }, [currentDate, startsOnSunday, showWeekends])

  useEffect(() => {
    if (!startDay || !endDay || !oneDayWidth || !numberOfWeeks) return

    setCurrentEventsInDays(
      Array.from({ length: numberOfWeeks }, (_, i) => i).map(i =>
        createEventSlotsInWeek(startDay.add(i, 'week'), events, showWeekends, oneDayWidth, onClosePopup)
      )
    )
  }, [numberOfWeeks, startDay, endDay, events, oneDayWidth])

  useEffect(() => {
    if (!startDay || !endDay) return
    setNumberOfWeeks(endDay.diff(startDay, 'week') + 1)
  }, [startDay, endDay])

  useEffect(() => {
    function handleResize() {
      if (!numberOfWeeks) return

      const calendarHeight = (document.querySelector('.calendar-view-month__body') as HTMLDivElement)?.offsetHeight
      const dayHeight = calendarHeight / numberOfWeeks
      const maxEvents = Math.floor(dayHeight / 30)
      setMaxEventsInDay(maxEvents > 1 ? maxEvents : 0)

      const dayCellDiv = document.querySelector('.calendar-view-month__header div')
      setOneDayWidth(dayCellDiv?.clientWidth || 0)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [numberOfWeeks, showWeekends, isSidebarVisible])

  return (
    <div className="calendar-view-month">
      <div className="calendar-view-month__header">
        {week.map((day, index) => (
          <div key={index}>{day.format('dddd')}</div>
        ))}
      </div>
      <div className="calendar-view-month__body">
        {currentEventsInDays.map((week, i) => (
          <div key={i} className="calendar-view-month__body__week">
            {week.map((dayEvents, dayIndex) => {
              const day = startDay.clone().add(i, 'week').add(dayIndex, 'day')
              const dayFilterString = day.format('YYYYMMDD')
              return (
                <DayCell
                  key={dayIndex}
                  day={day}
                  allEvents={events.filter(
                    a => a.startFilterString <= dayFilterString && a.endFilterString >= dayFilterString
                  )}
                  dayEvents={dayEvents}
                  onClosePopup={onClosePopup}
                  maxEventsInDay={maxEventsInDay}
                  moreCount={dayEvents?.length - maxEventsInDay + 1}
                  width={`${oneDayWidth}px`}
                />
              )
            })}
          </div>
        ))}
      </div>
    </div>
  )
}
