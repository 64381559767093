import './CalendarDayMore.less'

import { Popover } from 'antd'
import classNames from 'classnames'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { CalendarEvent } from '../../../../requests'
import { DatacIcon, DatacTitle } from '../../../common'
import { EventFullDayComponent, EventMonthComponent } from '../EventComponents'

interface Props {
  day: Date
  events: CalendarEvent[]
  onClosePopup: (reload: boolean) => void
  moreCount: number
}

export const CalendarDayMore: React.VFC<Props> = ({ day, events, moreCount, onClosePopup }) => {
  const intlCalendar = useScopedIntl('calendar')
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const dayLabel = dayjs(day).format('MM-DD')
  const [isBig, setIsBig] = useState(false)

  const adjustPopoverPosition = () => {
    const top = document.getElementById(dayLabel)?.getBoundingClientRect().top
    const overlay = document.querySelector(`.calendar-day-more__overlay--${dayLabel}`) as HTMLDivElement
    setIsBig(window.innerHeight < overlay?.clientHeight + top)
  }

  useEffect(() => {
    if (!isPopoverVisible) return

    setTimeout(adjustPopoverPosition, 10)
  }, [isPopoverVisible])

  return (
    <Popover
      className="calendar-day-more__show-more"
      overlayClassName={classNames('calendar-day-more__overlay', `calendar-day-more__overlay--${dayLabel}`, {
        'is-big': isBig
      })}
      open={isPopoverVisible}
      onOpenChange={setIsPopoverVisible}
      placement="bottomLeft"
      autoAdjustOverflow={false}
      getPopupContainer={() => document.getElementById(dayLabel)}
      arrow={false}
      content={
        <div className="calendar-day-more">
          <div className="calendar-day-more__header">
            <div className="calendar-day-more__header__date">
              <DatacTitle>{dayjs(day).format('MMM DD')}</DatacTitle>
              {dayjs(day).format('dddd')}
            </div>
            <DatacIcon name="x" onClick={() => setIsPopoverVisible(false)} />
          </div>
          <div className="calendar-day-more__events">
            {events &&
              events.map((a, i) =>
                a.isFullDay ? (
                  <EventFullDayComponent key={i} event={a} onClosePopup={onClosePopup} />
                ) : (
                  <EventMonthComponent key={i} event={a} onClosePopup={onClosePopup} />
                )
              )}
          </div>
        </div>
      }
      trigger="click"
    >
      + {intlCalendar('more', { count: moreCount })}
    </Popover>
  )
}
