import './CalendarContent.less'

import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { Popover } from 'antd'
import classNames from 'classnames'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useContext, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import {
  AclAction,
  AclFeature,
  AutomaticTimezone,
  CalendarEvent,
  SorterOrder,
  UserConfigKey,
  fetchCalendarEvents,
  fetchCenters
} from '../../../requests'
import { routes } from '../../../routes'
import { localeFromPath } from '../../../utils'
import { UserContext } from '../../auth'
import { DatacIcon, DatacLoading, DatacMessage } from '../../common'
import { CalendarLayout } from '../CalendarLayout'
import { defaultCalendarOptions, isCalendarView, useCalendarStore } from '../CalendarStore'
import { CalendarEventEdit } from './CalendarEventEdit'
import { CalendarEventSearch } from './CalendarEventSearch'
import { CalendarSettingsPopup } from './CalendarSettingsPopup'
import { CalendarViewAgenda } from './CalendarViewAgenda'
import { CalendarViewDay } from './CalendarViewDay'
import { CalendarViewMonth } from './CalendarViewMonth'
import { CalendarViewWeek } from './CalendarViewWeek'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(updateLocale)

interface CalendarContentProps extends RouteComponentProps {
  pathView?: string
  pathYear?: string
  pathMonth?: string
  pathDay?: string
  pathQuery?: string
}
export const CalendarContent: React.VFC<CalendarContentProps> = ({
  pathView,
  pathYear,
  pathMonth,
  pathDay,
  pathQuery
}) => {
  const intl = useScopedIntl('')
  const {
    currentView,
    setCurrentView,
    currentDate,
    setCurrentDate,
    requestDates,
    startsOnSunday,
    showWeekends,
    isToolbarMinimized,
    userTimezone,
    filters,
    setIsSidebarVisible,
    isSidebarVisible,
    setIsEditEventModalVisible,
    setCenterOptions,
    setUserTimezone,
    setShowWeekends,
    setStartsOnSunday,
    setShowSchedules,
    setIsToolbarMinimized,
    setFilters,
    isShowingSearchEvents,
    setIsShowingSearchEvents
  } = useCalendarStore()
  const [events, setEvents] = useState<CalendarEvent[]>([])
  const [isShowingSearch, setIsShowingSearch] = useState(false)
  const [isToolbarExpanded, setIsToolbarExpanded] = useState(false)
  const [isSettingsPopoverVisible, setIsSettingsPopoverVisible] = useState(false)
  const [isFetchingEvents, setIsFetchingEvents] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const [viewBeforeSearch, setViewBeforeSearch] = useState(currentView)
  const { user } = useContext(UserContext)

  const fetchCenterOptions = () => {
    fetchCenters(
      {
        options: { sorter: { field: 'abbreviation', order: SorterOrder.Ascend }, limit: 5 },
        assignedToUser: true
      },
      {
        onSuccess: ({ centers }) =>
          setCenterOptions(centers.map(c => ({ value: c.id, label: c.abbreviation, sublabel: c.name }))),
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const searchQuery = pathQuery && decodeURIComponent(pathQuery)
  const isSearchPath = pathView === 'search' && !!searchQuery
  const isPathPresent = !!Number(pathDay) && !!Number(pathMonth) && !!Number(pathYear) && isCalendarView(pathView)
  const isPathCorrect = () => {
    const currentYear = currentDate.format('YYYY')
    const currentMonth = currentDate.format('M')
    const currentDay = currentDate.format('D')
    return (
      (isSearchPath && currentView === 'agenda' && dayjs().isSame(currentDate, 'day')) ||
      (isPathPresent &&
        currentView === pathView &&
        currentYear === pathYear &&
        currentMonth === pathMonth &&
        currentDay === pathDay)
    )
  }

  useEffect(() => {
    if (!user?.id) return

    fetchCenterOptions()
    dayjs.locale(localeFromPath())
    const timezone = user.timezone === AutomaticTimezone ? dayjs.tz.guess() : user.timezone
    dayjs.tz.setDefault(timezone)
    setUserTimezone(timezone)
    setShowWeekends(user.getConfigValue(UserConfigKey.CalendarShowWeekends, defaultCalendarOptions.showWeekends))
    setStartsOnSunday(user.getConfigValue(UserConfigKey.CalendarStartsOnSunday, defaultCalendarOptions.startsOnSunday))
    setShowSchedules(user.getConfigValue(UserConfigKey.CalendarShowSchedules, defaultCalendarOptions.showSchedules))
    setIsToolbarMinimized(
      user.getConfigValue(UserConfigKey.CalendarMinimizeToolbar, defaultCalendarOptions.isToolbarMinimized)
    )
    setIsSidebarVisible(
      user.getConfigValue(UserConfigKey.CalendarSidebarVisible, defaultCalendarOptions.isSidebarVisible)
    )
    setFilters({ centers: user.getConfigValue<string[]>(UserConfigKey.CalendarCenters, []) })
    setIsReady(true)
  }, [user?.id])

  useEffect(() => {
    if (isSearchPath) {
      setViewBeforeSearch(currentView)
      setCurrentView('agenda', false)
      setCurrentDate(dayjs())
      setIsShowingSearchEvents(true)
      return
    }

    if (isPathPresent || !currentDate || !currentView) {
      setCurrentView(isPathPresent ? pathView : 'day', false)
      setCurrentDate(
        dayjs(isPathPresent ? new Date(Number(pathYear), Number(pathMonth) - 1, Number(pathDay)) : undefined)
      )
    }
  }, [pathDay, pathMonth, pathYear, pathView])

  useEffect(() => {
    if (!isReady || isPathCorrect() || !currentDate || isShowingSearchEvents) return

    navigate(routes.calendarFullPath(currentView, currentDate))
  }, [currentView, currentDate, isReady])

  const onClosePopup = (reload = false) => {
    if (reload) fetchEvents()
  }

  useEffect(() => {
    if (!isReady || !isPathCorrect()) return
    setEvents([])
    setIsFetchingEvents(true)
    fetchEvents(searchQuery)
  }, [requestDates, showWeekends, startsOnSunday, userTimezone, filters, isReady, searchQuery])

  useEffect(() => {
    dayjs.updateLocale(localeFromPath(), { weekStart: startsOnSunday ? 0 : 1 })
  }, [startsOnSunday])

  const fetchEvents = (search?: string) => {
    fetchCalendarEvents(search ? { search } : { ...requestDates, centerIds: filters?.centers }, {
      onSuccess: ({ events }) => {
        setEvents(events)
        setIsFetchingEvents(false)
      },
      onRequestError: code => DatacMessage.genericError(intl, code)
    })
  }

  const getViewComponent = () => {
    if (isFetchingEvents) return <DatacLoading transparent isLoading />
    if (isShowingSearchEvents) return <CalendarViewAgenda events={events} onClosePopup={onClosePopup} isSearch />

    switch (currentView) {
      case 'month':
        return <CalendarViewMonth events={events} onClosePopup={onClosePopup} />
      case 'day':
        return <CalendarViewDay showTimeColumn events={events} onClosePopup={onClosePopup} />
      case 'agenda':
        return <CalendarViewAgenda events={events} onClosePopup={onClosePopup} />
      default:
        return <CalendarViewWeek events={events} onClosePopup={onClosePopup} />
    }
  }

  const isFilteringActive = !!Object.values(filters).filter(a => !!(a as string[]).length).length

  const onSearchClose = () => {
    setIsShowingSearch(false)
    setIsShowingSearchEvents(false)
    navigate(routes.calendarFullPath(viewBeforeSearch, currentDate))
  }

  const onSearchOpen = () => {
    setIsShowingSearch(true)
    setTimeout(() => (document.querySelector('.calendar-event-search input') as HTMLInputElement).focus(), 100)
  }

  const toolbar = () => {
    if (isShowingSearch || isShowingSearchEvents)
      return (
        <>
          <DatacIcon name="arrowLeft" raw onClick={onSearchClose} />
          <CalendarEventSearch performedSearch={searchQuery} />
          <DatacIcon name="search" className="calendar-content__toolbox__inner__search" />
        </>
      )

    if (!isToolbarMinimized || isToolbarExpanded)
      return (
        <>
          {user.canDo(AclFeature.Calendar)(AclAction.Add) && isToolbarMinimized && (
            <DatacIcon name="plus" raw onClick={() => setIsEditEventModalVisible(true)} />
          )}
          <DatacIcon name="search" raw onClick={onSearchOpen} />
          <Popover
            trigger="click"
            placement={isToolbarMinimized ? 'left' : 'topLeft'}
            open={isSettingsPopoverVisible}
            onOpenChange={setIsSettingsPopoverVisible}
            content={<CalendarSettingsPopup onClose={() => setIsSettingsPopoverVisible(false)} />}
            overlayClassName="calendar-content__toolbox__inner__popover"
            arrow={false}
          >
            <DatacIcon raw name="settings" />
          </Popover>

          <div className="calendar-content__toolbox__inner__filter">
            {isFilteringActive && <div className="calendar-content__toolbox__inner__filter__badge"> </div>}
            <DatacIcon name="filters" raw onClick={onToggleSidebar} />
          </div>
        </>
      )

    return null
  }

  const onCollapseToolbar = () => {
    setIsToolbarExpanded(false)
    onSearchClose()
  }

  const onToggleSidebar = () => {
    user.setConfigValue(UserConfigKey.CalendarSidebarVisible, !isSidebarVisible)
    setIsSidebarVisible(!isSidebarVisible)
  }

  const isSearchExpanded = isShowingSearchEvents || isShowingSearch

  return (
    <CalendarLayout>
      <div className="calendar-content">
        <div className="calendar-content__body">{getViewComponent()}</div>
        <div
          className={classNames('calendar-content__toolbox', {
            'sidebar-visible': isSidebarVisible,
            minimized: isToolbarMinimized
          })}
        >
          <div
            className={classNames(
              'calendar-content__toolbox__inner',
              { 'search-visible': isSearchExpanded },
              { 'minimized-collapsed': isToolbarMinimized && !isSearchExpanded && !isToolbarExpanded },
              { 'minimized-expanded': isToolbarMinimized && !isSearchExpanded && isToolbarExpanded }
            )}
            onBlur={() => setIsToolbarExpanded(false)}
          >
            {toolbar()}
          </div>
        </div>
        {user.canDo(AclFeature.Calendar)(AclAction.Add) && !isToolbarMinimized && (
          <DatacIcon
            className="calendar-content__bottom-icon"
            name="plus"
            type="white"
            onClick={() => setIsEditEventModalVisible(true)}
            key="bottom-icon"
          />
        )}
        {isToolbarMinimized && (isToolbarExpanded || isShowingSearch) && (
          <DatacIcon
            className="calendar-content__bottom-icon"
            name="x"
            type="red"
            onClick={onCollapseToolbar}
            key="bottom-icon"
          />
        )}
        {isToolbarMinimized && (!isToolbarExpanded || isShowingSearch) && (
          <DatacIcon
            className="calendar-content__bottom-icon"
            name="menu"
            type="white"
            onClick={() => setIsToolbarExpanded(true)}
            key="bottom-icon"
          />
        )}
      </div>
      {(user.canDo(AclFeature.Calendar)(AclAction.Add) || user.canDo(AclFeature.Calendar)(AclAction.Edit)) && (
        <CalendarEventEdit onSubmitted={() => fetchEvents()} />
      )}
    </CalendarLayout>
  )
}
