import './CalendarLayout.less'

import classNames from 'classnames'
import dayjs from 'dayjs'
import 'dayjs/locale/de'
import 'dayjs/locale/en-gb'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import 'dayjs/locale/it'
import 'dayjs/locale/ja'
import 'dayjs/locale/pl'
import 'dayjs/locale/pt'
import 'dayjs/locale/zh-cn'
import React, { useContext, useEffect } from 'react'

import { useScopedIntl } from '../../../hooks'
import { AclFeature } from '../../../requests'
import { localeFromPath } from '../../../utils'
import { RedirectNoAccessWrapper } from '../../RedirectNoAccessWrapper'
import { UserContext } from '../../auth'
import { DatacMessage } from '../../common'
import { useCalendarStore } from '../CalendarStore'
import { CalendarLayoutHeader } from './CalendarLayoutHeader'
import { CalendarLayoutSidebar } from './CalendarLayoutSidebar'

export const CalendarLayout: React.FC = ({ children }) => {
  const intl = useScopedIntl('')
  const { user } = useContext(UserContext)
  const { startsOnSunday, isSidebarVisible } = useCalendarStore()

  useEffect(() => {
    if (!user?.id) return

    if (user.shouldShowNextReleaseInfo()) {
      DatacMessage.nextReleaseInfo(intl, user.getNextRelease(), user.closeNextReleaseNotes, user.language)
    }
  }, [user])

  useEffect(() => {
    dayjs.updateLocale(localeFromPath(), { weekStart: startsOnSunday ? 0 : 1 })
  }, [startsOnSunday])

  return (
    <RedirectNoAccessWrapper
      hasNoAccess={!user.isCalendarEnabled || !user.canAccess(AclFeature.Calendar)}
      path="calendar"
    >
      <div className={classNames('calendar-layout', !isSidebarVisible && 'calendar-layout--minimized')}>
        <CalendarLayoutHeader />
        <CalendarLayoutSidebar />
        <main className="calendar-layout__content">{children}</main>
      </div>
    </RedirectNoAccessWrapper>
  )
}
