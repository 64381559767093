import './EventEditStudy.less'

import { debounce } from 'lodash'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import {
  EventStudyType,
  fetchProject,
  fetchProjects,
  fetchRecruitmentStudies,
  fetchRecruitmentStudy,
  fetchStudies,
  fetchStudy
} from '../../../../../requests'
import { DatacFormItem, DatacLoading, DatacMessage, DatacOption, DatacSelect } from '../../../../common'
import { useCalendarStore } from '../../../CalendarStore'

const studiesListLimit = 3

interface EventEditStudyProps {
  studyId: string
  setStudyId: (studyId: string) => void
  studyType: EventStudyType
  setStudyType: (studyType: EventStudyType) => void
}

export const EventEditStudy: React.VFC<EventEditStudyProps> = ({ setStudyId, studyId, studyType, setStudyType }) => {
  const intlStudy = useScopedIntl('calendar.event.study')
  const intl = useScopedIntl('')
  const [edcOptions, setEdcOptions] = useState<DatacOption[]>([])
  const [ecrfOptions, setEcrfOptions] = useState<DatacOption[]>([])
  const [projectOptions, setProjectOptions] = useState<DatacOption[]>([])
  const [options, setOptions] = useState<DatacOption[]>([])
  const { eventToEdit: appointmentToEdit } = useCalendarStore()
  const [isSearchingEdc, setIsSearchingEdc] = useState(true)
  const [isSearchingEcrf, setIsSearchingEcrf] = useState(true)
  const [isSearchingProjects, setIsSearchingProjects] = useState(true)

  useEffect(() => {
    onSearchStudies()

    if (!appointmentToEdit?.study?.uuid) return

    switch (studyType) {
      case EventStudyType.ecrf:
        fetchStudy(
          { studyId: appointmentToEdit.study?.uuid },
          {
            onSuccess: study => {
              setEcrfOptions([
                ...ecrfOptions.filter(s => s.value !== study.id),
                {
                  label: study.reference,
                  sublabel: study.name,
                  value: `${EventStudyType.ecrf}.${study.id}`
                }
              ])
            }
          }
        )
        break
      case EventStudyType.edc:
        fetchRecruitmentStudy(
          { studyId: appointmentToEdit.study?.uuid },
          {
            onSuccess: study => {
              setEcrfOptions([
                ...ecrfOptions.filter(s => s.value !== study.id),
                {
                  label: study.reference,
                  sublabel: study.name,
                  value: `${EventStudyType.edc}.${study.id}`
                }
              ])
            }
          }
        )
        break
      default:
        fetchProject(
          { projectId: appointmentToEdit.study?.uuid },
          {
            onSuccess: study => {
              setEcrfOptions([
                ...ecrfOptions.filter(s => s.value !== study.id),
                {
                  label: study.name,
                  sublabel: study.name,
                  value: `${EventStudyType.project}.${study.id}`
                }
              ])
            }
          }
        )
        break
    }
  }, [appointmentToEdit])

  const onSearchStudies = debounce((searchPhrase?: string) => {
    const options = {
      limit: studiesListLimit,
      offset: 0,
      search: searchPhrase
    }
    setIsSearchingEdc(true)
    setIsSearchingEcrf(true)
    setIsSearchingProjects(true)

    fetchRecruitmentStudies(
      { options },
      {
        onSuccess: newStudies => {
          setEdcOptions([
            ...edcOptions.filter(s => s.value === `${EventStudyType.edc}.${studyId}`),
            ...newStudies
              .filter(s => s.id !== studyId)
              .map(s => ({ label: s.reference, sublabel: s.name, value: `${EventStudyType.edc}.${s.id}` }))
          ])
          setIsSearchingEdc(false)
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )

    fetchStudies(
      { options },
      {
        onSuccess: newStudies => {
          setEcrfOptions(ecrfOptions => [
            ...ecrfOptions.filter(s => s.value === `${EventStudyType.ecrf}.${studyId}`),
            ...newStudies
              .filter(s => s.id !== studyId)
              .map(s => ({ label: s.reference, sublabel: s.name, value: `${EventStudyType.ecrf}.${s.id}` }))
          ])
          setIsSearchingEcrf(false)
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )

    fetchProjects(
      { options },
      {
        onSuccess: newStudies => {
          setProjectOptions([
            ...projectOptions.filter(s => s.value === `${EventStudyType.project}.${studyId}`),
            ...newStudies
              .filter(s => s.id !== studyId)
              .map(s => ({ label: s.name, value: `${EventStudyType.project}.${s.id}` }))
          ])
          setIsSearchingProjects(false)
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }, 1000)

  const onStudySelect = (value: string) => {
    const selected = value?.split('.')
    setStudyType(selected?.[0] as EventStudyType)
    setStudyId(selected?.[1])
  }

  useEffect(() => {
    setOptions([
      { label: intlStudy('edc'), disabled: true, value: 'edc' },
      ...edcOptions,
      { label: intlStudy('ecrf'), disabled: true, value: 'ecrf' },
      ...ecrfOptions,
      { label: intlStudy('projects'), disabled: true, value: 'projects' },
      ...projectOptions
    ])
  }, [edcOptions, ecrfOptions, projectOptions])

  const isSearching = isSearchingProjects || isSearchingEcrf || isSearchingEdc
  return (
    <DatacFormItem name="studyId" icon="fileText" isCalendarStyle label={intlStudy('label')} validate={[]}>
      <DatacSelect
        size="large"
        options={isSearching ? [{ value: null, label: <DatacLoading isLoading size="small" /> }] : options}
        placeholder={intlStudy('placeholder')}
        onSearch={onSearchStudies}
        doNotFilterResults
        showSearch
        onChange={onStudySelect}
        allowBlank={!isSearching}
        dropDownClassName="calendar-event-edit-study__dropdown"
      />
    </DatacFormItem>
  )
}
