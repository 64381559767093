import './EventPeopleSelect.less'

import { RefSelectProps } from 'antd'
import { debounce } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import {
  EventPerson,
  SorterOrder,
  SubjectInRepository,
  UserData,
  fetchSubjectsInRepository,
  fetchUsers
} from '../../../../../requests'
import { DatacAvatar, DatacIcon, DatacMessage, DatacOption, DatacSelect, DatacTooltip } from '../../../../common'

const maxPeopleInRowNr = 11

export const parseEventPersonOption = (person: UserData | SubjectInRepository | EventPerson) => {
  const getPersonName = () => {
    if ('name' in person && person.name) return person.name
    if ('fullName' in person && person.fullName) return person.fullName
    if ('lastName' in person && (person.lastName || person.firstName)) return `${person.firstName} ${person.lastName}`
    if ('datacaptId' in person && person.datacaptId) return person.datacaptId
    return person.id
  }

  return {
    value: 'pk' in person ? String(person.pk) : String(person.id),
    prefix: <DatacAvatar fullName={getPersonName()} size="xsmall" />,
    suffix: <DatacIcon type="grey" name="plus" />,
    label: getPersonName(),
    sublabel: person.email || ' '
  }
}

export enum PeopleSelectType {
  Participants = 'participants',
  Attendees = 'attendees'
}

interface EventPeopleSelectListProps {
  currentSelection: DatacOption[]
  setCurrentSelection?: (selection: DatacOption[]) => void
}

export const EventPeopleSelectList: React.VFC<EventPeopleSelectListProps> = ({
  currentSelection,
  setCurrentSelection
}) => {
  const intlEvent = useScopedIntl('calendar.event')
  const [showAll, setShowAll] = useState(false)
  return (
    !!currentSelection.length && (
      <div className="calendar-event-people-select__list">
        {currentSelection
          .slice(0, showAll ? currentSelection?.length : maxPeopleInRowNr)
          .map(({ value, prefix, label }) => (
            <DatacTooltip title={intlEvent('delete_person', { name: label as string })} key={value}>
              <div
                className="calendar-event-people-select__list__person"
                onClick={() => setCurrentSelection?.(currentSelection.filter(s => s.value !== value))}
              >
                {prefix}
                <DatacIcon name="minus" raw className="calendar-event-people-select__list__person__trash" />
              </div>
            </DatacTooltip>
          ))}
        {!showAll && currentSelection?.length > maxPeopleInRowNr && (
          <div className="calendar-event-people-select__list__more" onClick={() => setShowAll(true)}>
            + {currentSelection.length - maxPeopleInRowNr}
          </div>
        )}
      </div>
    )
  )
}

interface EventPeopleSelectProps {
  currentSelection: DatacOption[]
  setCurrentSelection: (selection: DatacOption[]) => void
  isVisible: boolean
  type: PeopleSelectType
  isAlwaysOpened?: boolean
  dropDownClassName?: string
  setPeople?: (people: EventPerson[]) => void
  capacity?: number
}

export const EventPeopleSelect: React.VFC<EventPeopleSelectProps> = ({
  currentSelection,
  setCurrentSelection,
  isVisible,
  type,
  isAlwaysOpened,
  dropDownClassName,
  setPeople,
  capacity
}) => {
  const intlEvent = useScopedIntl('calendar.event')
  const selectRef = useRef<RefSelectProps>()
  const [options, setOptions] = useState<DatacOption[]>([])
  const [isFetchingList, setIsFetchingList] = useState(false)
  const intl = useScopedIntl('')

  useEffect(() => {
    if (!selectRef) return

    const searchInput = selectRef.current?.nativeElement?.querySelector('.ant-select-selection-search-input')
    searchInput?.setAttribute(
      'placeholder',
      intlEvent(type === PeopleSelectType.Attendees ? 'attendees.placeholder' : 'participants.placeholder')
    )
  }, [selectRef, currentSelection, isVisible])

  useEffect(() => {
    if (!isVisible) return
    setOptions([])
    onSearch()
  }, [isVisible])

  const updateOption = (option: DatacOption) => {
    const isSelected = currentSelection.map(({ value }) => value).includes(option.value)
    const canBeAdded = !capacity || capacity > currentSelection.length
    return {
      ...option,
      suffix: isSelected ? (
        <>
          <DatacIcon type="blue" name="check" className="calendar-event-people-select__dropdown__icon check" />
          <DatacIcon type="red" name="trash" className="calendar-event-people-select__dropdown__icon trash" />
        </>
      ) : (
        canBeAdded && <DatacIcon type="grey" name="plus" />
      ),
      disabled: !canBeAdded && !isSelected
    }
  }

  const setSelection = (ids: string[]) => {
    setCurrentSelection(options.filter(o => ids.includes(o.value)))
  }

  useEffect(() => {
    setOptions(c => c.map(updateOption))
  }, [currentSelection, capacity])

  const onSearchAttendees = debounce((search = '') => {
    setIsFetchingList(true)
    fetchUsers(
      { options: { search, limit: 25, offset: 0, sorter: { order: SorterOrder.Ascend, field: 'name' } } },
      {
        onSuccess: ({ users }) => {
          if (setPeople) {
            setPeople(users.map(u => ({ id: u.id, email: u.email, fullName: u.name })))
          }
          setOptions([
            ...currentSelection.map(updateOption),
            ...users
              .map(u => parseEventPersonOption(u))
              .filter(u => !currentSelection.map(({ value }) => value).includes(u.value))
              .map(updateOption)
          ])
          setIsFetchingList(false)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingList(false)
        }
      }
    )
  }, 500)

  const onSearchParticipants = debounce((search = '') => {
    setIsFetchingList(true)
    fetchSubjectsInRepository(
      {
        search,
        limit: 25,
        offset: 0,
        sorter: { order: SorterOrder.Descend, field: 'firstName' },
        conditions: []
      },
      {
        onSuccess: ({ subjects }) => {
          if (setPeople) {
            setPeople(
              subjects.map(s => ({
                id: String(s.pk),
                email: s.email,
                datacaptId: s.id,
                fullName: s.firstName && `${s.firstName} ${s.lastName}`
              }))
            )
          }
          setOptions([
            ...currentSelection.map(updateOption),
            ...subjects
              .map(u => parseEventPersonOption(u))
              .filter(u => !currentSelection.map(({ value }) => value).includes(u.value))
              .map(updateOption)
          ])
          setIsFetchingList(false)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingList(false)
        }
      }
    )
  }, 500)

  const onSearch = type === PeopleSelectType.Attendees ? onSearchAttendees : onSearchParticipants

  return (
    <DatacSelect
      selectRef={selectRef}
      mode="multiple"
      showSearch
      className="calendar-event-people-select__search"
      dropDownClassName={`calendar-event-people-select__dropdown ${dropDownClassName || ''}`}
      options={options}
      onSearch={onSearch}
      loading={isFetchingList}
      onChange={setSelection}
      value={currentSelection.map(({ value }) => value)}
      open={isAlwaysOpened}
      getPopupContainer={triggerNode => (isAlwaysOpened ? triggerNode.parentNode : undefined)}
      doNotFilterResults
    />
  )
}
