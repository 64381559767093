import { Router } from '@gatsbyjs/reach-router'
import React from 'react'

import { BasicLayout } from '../../components/BasicLayout'
import { AuthRoute } from '../../components/auth/AuthRoute'
import { SessionTimeoutModal } from '../../components/auth/SessionTimeoutModal'
import { CalendarContent } from '../../components/calendar/CalendarContent'
import { AccountType } from '../../requests'

const Calendar = () => {
  return (
    <>
      <SessionTimeoutModal accountType={AccountType.User} />
      <AuthRoute>
        <BasicLayout>
          <Router basepath="/:lang/calendar">
            <CalendarContent path="/" />
            <CalendarContent path="/:pathView/:pathQuery" />
            <CalendarContent path="/:pathView/:pathYear/:pathMonth/:pathDay" />
          </Router>
        </BasicLayout>
      </AuthRoute>
    </>
  )
}

export default Calendar
export const Head = () => <title>Datacapt - Calendar</title>
